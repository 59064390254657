.DashboardModule-container-parent-class .representative__second{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 12px 16px 12px;
}
.DashboardModule-container-parent-class .representative__second .representative__second__btn{
    background: var(--blue-3);
    color: var(--white);
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
}
.DashboardModule-container-parent-class .representative__second .representative__second__btn:hover{
    background: #40b1d6;
}
.DashboardModule-container-parent-class .twilio__migration__droup__down .MuiSelect-select{
    align-items: center !important;
}
.DashboardModule-container-parent-class .twilio__migration__droup__down .MuiSelect-select:focus{
    height: unset !important;
}
.DashboardModule-container-parent-class .twilio__migration__droup__down .MuiFormControl-root.second__step__area__select__100{
    height: auto !important;
}

.DashboardModule-container-parent-class .twilio_add_another_auth_radio_wr .twilio_add_another_auth_label{
    padding-left: 27px !important;
}
.DashboardModule-container-parent-class .twilio_add_another_auth_radio_wr{
    display: flex;
    grid-gap: 6px;
    margin: 6px 0 0 -5px;

}