.meis_switch_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    width: 104px;
    height: 32px;
    cursor: pointer;
    border-radius: 16px;
    box-sizing: border-box;
    position: relative;
}
.meis_switch_wrapper .meis_sw_indicator,
.meis_switch_wrapper .meis_sw_label
{
    transition: all 0.15s ease-in-out;
}
.meis_switch_wrapper.meis_sw_disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.meis_switch_wrapper.meis_sw_active .meis_sw_l_off{
    display: none;
}
.meis_switch_wrapper.meis_sw_inactive .meis_sw_l_on{
    display: none;
}
.meis_switch_wrapper .meis_sw_indicator{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
    border-radius: 16px;
}
.meis_switch_wrapper .meis_sw_label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.16px;
}
.meis_switch_wrapper.meis_sw_active .meis_sw_indicator{
    left: calc(100% - 28px);
    position: absolute;
    z-index: 1;
}
.meis_switch_wrapper.meis_sw_active .meis_sw_label.meis_sw_l_on{
    left: 4px;
    position: absolute;
}
.meis_switch_wrapper.meis_sw_inactive .meis_sw_indicator {
    position: absolute;
    left: 4px;
    z-index: 1;
}
.meis_switch_wrapper.meis_sw_inactive .meis_sw_label.meis_sw_l_off{
    position: absolute;
    right: 4px;
}