.dashboard_low_credit_alert_wrapper{
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    padding: 20px 0px;
}
.dashboard_low_credit_alert_wrapper .dlcaw_image_wrapper .dlcaw_iw_image{
    width: 260px;
    height: 200px;
    object-fit: cover;
    border-radius: 14px;
}
.dashboard_low_credit_alert_wrapper .dlcaw_right_side_wrapper .dlcaw_rsw_header_text{
    font-size: 22px;
    font-weight: 700;
    justify-content: center;
    display: flex;
}
.dashboard_low_credit_alert_wrapper .dlcaw_right_side_wrapper .dlcaw_rsw_des_text p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #627692;
    padding: 3px 10px;
    text-align: center;
}
.dashboard_low_credit_alert_wrapper .dlcaw_right_side_wrapper .dlcaw_action_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}
.dashboard_low_credit_alert_wrapper .dlcaw_right_side_wrapper .dlcaw_action_wrapper .dlcaw_aw_button{
    padding: 7px 20px;
    background: #6070ed;
    border-radius: 7px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
}
.dashboard_low_credit_alert_wrapper .dlcaw_right_side_wrapper .dlcaw_rsw_hints{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #627692;
    text-align: center;
    justify-content: center;
}
