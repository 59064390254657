.processing_modal_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 400px;
}

.processing_modal_wrapper .processing_modal_container{
    max-width: 590px;
    margin: 0 auto;
    text-align: center;
}
.processing_modal_wrapper .processing_modal_container img {
    width: 80px;
}
.processing_modal_wrapper .processing_modal_container_title {
    font-size: 17px;
    font-weight: 500;
    margin: 16px 0 35px 0;
    line-height: 24px;
}
.processing_modal_wrapper .processing_modal_container_content{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin:0;
}

.processing_modal_wrapper .processing_modal_container .processing_modal_check_btn{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue-2);
    border-radius: 50%;
    margin: 0 auto;
}
.processing_modal_wrapper .processing_modal_container .processing_modal_check_btn svg path{
    fill: #fff;
}
.processing_modal_wrapper .processing_modal_container .processing_modal_go_step_btn{
    background-color: var(--blue-2);
    padding: 7px 11px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;


}

/* completeing section */
.processing_modal_wrapper .processing_modal_complete svg {
    font-size: 50px;
}
.processing_modal_wrapper .processing_modal_complete svg path{
    fill: var(--orange);
}

.processing_modal_container .processing_modal_container_v2_update{
    padding: 14px 0 27px 0;
}

.processing_modal_container .processing_modal_container_v2_thumb svg{
    color: #ffd500e6;
    font-size: 58px;
}
.processing_modal_container .processing_modal_container_v2_title{
    margin: 0 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    
}
.processing_modal_container .processing_modal_go_step_btn_update{
    padding: 7px 15px !important;
    border-radius: 12px !important;
}