.restrict_alert_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 400px;
    padding: 30px;
}

.restrict_alert_container{
    max-width: 590px;
    margin: 0 auto;
    text-align: center;
}

.restrict_alert_container_title {
    font-size: 17px;
    font-weight: 500;
    margin: 16px 0 35px 0;
    line-height: 24px;
}
.restrict_alert_container_content{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin:0;
}

.restrict_alert_go_step_btn{
    border: 1px solid #E5E8EF;
    border-radius: 7px;
    padding: 20px;
    color: #000;
    font-weight: 700;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.restrict_alert_go_back_btn{
    border: 1px solid #E5E8EF;
    border-radius: 7px;
    padding: 20px;
    color: #000;
    font-weight: 700;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
}

.restrict_alert_container_v2_update{
    padding: 14px 0 27px 0;
}

.restrict_alert_container_v2_title{
    font-size: 23px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
}
.restrict_alert_container_v2_description{
    font-size: 14px;
    font-weight: 500;
    text-align: justify;
    line-height: 20px;
}
.restrict_alert_go_step_btn_update{
    padding: 7px 15px !important;
    border-radius: 12px !important;
}

.highlightRed {
    background-color: red;
    color: white;
    padding: 7px;
    font-size: 18px;
}

.highlightYellow {
    background-color: orange;
    color: white;
}