.modalWrapper {
    display: grid;
    place-content: center;
}

.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.headerWrapper {
    display: flex;
    align-items: center;
    padding: 0;
}

.headerInner {
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.headerTitle {
    font-size: 20px;
    margin: 0;
    padding: 0;
    color: #e51717;
}

.modalBody {

}

.bodyContainer {
    padding: 0 14px 24px;
    overflow-y: auto;
    width: 600px;
}

.bodyContainer::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.bodyContainer::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.bodyContainer::-webkit-scrollbar {
    scrollbar-width: thin;
}
.bodyContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.bodyContainer::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.closeCircleIcon svg{
    background-color: transparent;
    width: 25px;
    height:25px;
    border-radius: 50px;
    cursor:pointer;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.bodyTextContainer {
    text-align: center;
    padding: 20px;
    color: #656379;
}

.messageTitle {
    font-size: 13px;
    text-align: center;
}

.textStrong {
    font-weight: bold;
}

.actionContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.actionButton {
    background-color: #9595df;
    padding: 15px 30px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
}

.actionButton:hover {
    opacity: .9;
}

.riskActionButton {
    background-color: #e11f1f;
    padding: 15px 30px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.riskActionButton:hover {
    opacity: .9;
}

.reasonTitle {
    padding: 10px;
    text-align: center;
    font-size: 15px;
    font-family: "Poppins", serif;
    margin-bottom: 10px;
}

.zoomInZoomOut {
    animation: zoomInZoomOut 2s ease-out infinite;
}

@keyframes zoomInZoomOut {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

.bwsrIcons {
    display: flex;
    justify-content: space-between;
}

.bwsrIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: antiquewhite;
    width: 70px;
    font-size: 11px;
    padding: 6px 40px;
    border-radius: 4px;
    cursor: pointer;
}

.bwsrIcon img {
    width: 30px;
    height: 30px
}

.bwsrIcon span {
    color: #787878;
}