.DashboardModule-container-parent-class .claim__contact__container{
    background: #fff;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    box-shadow: 1px 3px 20px rgba(0,0,0,0.3);
    padding: 30px;
}
.DashboardModule-container-parent-class .claim__contact__container .claim__contact__avater{
    background: var(--gray);
    display: inline-flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
}

.DashboardModule-container-parent-class .claim__contact__container .claim__contact__avater__title{
    font-weight: 400px;
    padding: 6px 0;
}
.DashboardModule-container-parent-class .claim__contact__container .claim__contact_details_header__title{
    font-size: 18px;
    text-align: center;
    padding: 0 0 30px 0;

}
.DashboardModule-container-parent-class .claim__contact__container  .claim__contact_details_header{
    text-align: center;
}
.DashboardModule-container-parent-class .claim__contact__container .claim__action__button{
    text-align: center;
}
.DashboardModule-container-parent-class .claim__contact__container .claim__button{
    background: var(--light_blue);
    color: #fff;
    display: inline-flex;
    padding: 5px 11px;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 8px;
}