.sole_pro_ship_campaign_registration_modal_view {
    height: 600px !important;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container{
    grid-gap: 16px;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .seu__up__list__h4{
    margin: 0px 0px 1.25rem;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    display: block;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .seu__up__list__p1{
    margin: 0px 0px 1.5rem;
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field__content{
    margin-bottom: 20px;
    padding: 10px;
    background-color: #dddddd5e;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field__label{
    color: rgb(18, 28, 45) !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field_dropdown{
    margin-top: 8px;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field__input textarea{
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 14px 16px !important;
    margin-top: 8px !important;
    font-size: 16px !important;
    line-height: 24px;
    background: #fff;
    color: #8896A8;
    height: 66px !important;
    resize: none;
    width: 100% !important;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field__input input{
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 14px 16px !important;
    margin: 8px 0 0 0px !important;
    font-size: 16px !important;
    line-height: 24px;
    background: #fff;
    color: #8896A8;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field__input textarea:focus{
    outline: 0;
}
.MuiPaper-root .MuiButtonBase-root.menuitem__li__display__block{
    display: block !important;
}
.menuitem__li__display__block .each__item__title,
.menuitem__li__display__block .each__item__des{
    font-size: 14px;
    margin: 0px;
    padding: 0px;
}
.menuitem__li__display__block .each__item__des{
    font-size: 12px !important;
    margin-top: 2px;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .extra__bottom__label{
    color: rgb(37 64 109 / 89%);
    font-size: 0.73rem;
    line-height: 1.1rem;
    font-weight: 400;
}

.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .asterisk{
    color: #b71f1f;
    font-size: 14px;
    margin-right: 8px;
}

.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .campaign__registration__count__v2{
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--dark_blue) !important;
}

.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .counter__overwrite__wrapper{
    position: relative;
}

.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .counter__overwrite {
    position: absolute;
    right: 10px;
    bottom: 12px;
    font-size: 10px !important;
}

.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .step__4__checkbox{
    margin-top: 8px;
}
.sole_pro_ship_campaign_registration_modal_view .campaign__registration__container .field__input__checkbox{
    display: flex;
    grid-gap: 5px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}

.regis_cap_use_case_menu_pop_v2{
    max-width: 446px !important;
    width: 100%;
}
.regis_cap_use_case_menu_pop_v2 .MuiList-root .MuiListItem-root p{
    white-space: normal !important;
}
.dua_campaign_registration{
    max-width: 900px !important;
    min-height: 600px;
    max-height: calc(100vh - 200px) !important;
}
.dua_campaign_registration .dua_campaign_registration_plz_wait{
    height: calc(100vh - 234px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray);
    font-size: 14px;
}
.dua_campaign_registration .dua_campaign_registration_have_no_account{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: calc(100vh - 234px);

}
.dua_campaign_registration .dua_campaign_registration_have_no_account .dua_campaign_registration_hoa_close{
    background: var(--red);
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 4px;
    margin-top: 16px;
    cursor: pointer;
}
.dua_campaign_registration .dua_campaign_registration_have_no_account .dua_campaign_registration_hoa_close:hover{
    background: rgb(206, 6, 6);
}
.dua_campaign_registration .dua_campaign_registration_faild{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: calc(100vh - 234px);
}
.dua_campaign_registration .dua_campaign_registration_faild_button{
    display: flex;
    grid-gap: 8px;
    align-items: center;
    justify-content: center;
}
.dua_campaign_registration .dua_campaign_registration_faild_btn_close{
    background: var(--red);
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 4px;
    margin-top: 16px;
    cursor: pointer;
}
.dua_campaign_registration .dua_campaign_registration_faild_btn_try_again{
    background: var(--dark_blue);
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 4px;
    margin-top: 16px;
    cursor: pointer;
}

.dua_campaign_registration .campaign__registration__container .field__content .field__input .MuiInputBase-root{
    width: 100%;
}

.dua_campaign_registration .campaign__registration__container .field__content .field__input .MuiSelect-root{
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    padding: 11px 16px!important;
    color: #8896a8;
}
.dua_campaign_registration .crmv_submit_container .crmv_submit_container_reg_btn{
    background: var(--dark_blue);
    color: #fff;
    display: inline-block;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}

.dua_campaign_registration #modal___body___container___id {
    padding-top: 0 !important;
    padding-right: 0 !important;
}

.impo_message_container {
    /*filter: drop-shadow(0 0 8px cyan);*/
    margin-bottom: 20px !important;
}

.impo_message_container .impo_message {
    overflow: hidden;
    transition: all .5s;
    border-left-width: 5px;
    margin: 0 10px 0 auto;
   /*background-image: linear-gradient(to right, #a10b0b , #ff0000c7);*/
    background-color: #f6eef9;
}

.impo_message_container .impo_title {
    display: flex;
    padding: 0.5em 0.5em;
    font-weight: bold;
    letter-spacing: .1em;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    align-items: center;
    gap: 8px;
    color: #41214e;
}

.impo_message_container  p {
    color: #41214e;
    padding: 0 1em 1em 1em;
    margin: 0;
    display: block;
    line-height: 1.4em;
    text-align: justify;
    max-height: 10em;
    font-size: 14px;
}

.sole_pro_ship_campaign_registration_modal_view input:focus {
    box-shadow: none !important;
}

.use-random-button {
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 5px 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    float: right;
    clear: left;
}

.use-random-button:focus {
    background-color: #4CAF50 !important;
}

.use-random-button:active {
    background-color: #3e8e41;
    box-shadow: 0 1px #666;
    transform: translateY(2px);
}