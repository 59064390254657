/* modal */
.tpdModalMainWrpWidth600{
    width: 600px !important;
}
.tpdModalMainWrpWidth800{
    width: 800px !important;
}
.tpdModalMainWrpHeight90P{
    height: 90vh !important;
}
/* action required start */
.tdpArWrp{
    background: #f6f7fb !important;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.tdpArWrp .tdpAwHeader{
    border-bottom: 1px solid #E5E8EF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}
.tdpArWrp .tdpAwHeader .tdpAwHeaderIcon{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.tdpArWrp .tdpAwFooter{
    border-top: 1px solid #E5E8EF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tdpArWrp .tdpAwFooter .tdpAwFooterButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    color: #fff;
    background: #FF264A;
    border-radius: 5px;
    padding: 10px 20px;
}
.tdpArWrp .tdpAwBodyWrp{
    /* height: calc(100% - 130px) !important; */
    height: calc(100% - 70px) !important;
    background-color: #F2F3F5;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    /* display: flex;s */
}
.tdpArWrp .tdpAwBodyWrp .tdp_aw_info_wrp{
    background: red;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.tdpArWrp .tdp_aw_info_wrp .tdp_aw_iw_price_text{
    text-align: justify;
    color: #fff;
    font-size: 12px;
}
.tdpArWrp .tdp_aw_info_wrp .tdp_aw_iw_number_text{
    text-align: center;
    color: #fff;
    font-size: 12px;
}
.tdpArWrp .tdpAwBodyWrp .tdp_aw_des_wrp{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;
    padding: 0px 10px;
    text-align: justify;
    font-weight: 300;
    font-size: 14px;
}
.tdpArWrp .tdp_aw_des_wrp .tdp_aw_dw_title{
    font-weight: bold !important;
}
.tdpArWrp .tdp_aw_des_wrp .tdp_aw_dw_link{
    color: #039be5;
    border-bottom: unset;
    text-decoration: none;
    cursor: pointer;
    font-weight: bolder !important;
}
.tdpArWrp .tdpAwBodyWrp .tdp_aw_button_wrp{
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tdpArWrp .tdp_aw_button_wrp .tdp_aw_bw_button{
    background: #133159;
    color: #fff;
    max-width: 200px;
    display: inline-flex;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}
/* congratulation screen */
.tdp_congratulation_wrp{
    width: 100%;
    height: 100%;
    display: grid;
    text-align: center;
    place-content: center;
}
.tdp_congratulation_wrp .tdp_cw_message_wrp{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}
.tdp_congratulation_wrp .tdp_cw_message_wrp .tdp_cw_mw_text{
    font-size: 16px;
}
.tdp_congratulation_wrp .tdp_cw_mw_button{
    display: flex;
    width: auto;
    height: 40px;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2D9CDB;
    color: #FFF;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
/* success screen */
.tdp_success_wrp{
    width: 100%;
    height: 100%;
    display: grid;
    text-align: center;
    place-content: center;
}
.tdp_success_wrp .tdp_sw_check_icon{
    background-color: #2D9CDB;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.tdp_success_wrp .tdp_sw_message{
    margin: 20px 0px;
    font-size: 16px;
}
.tdp_success_wrp .tdp_sw_mw_button{
    display: flex;
    width: auto;
    height: 40px;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2D9CDB;
    color: #FFF;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
/* failed screen */
.tdp_failed_wrp{
    width: 100%;
    height: 100%;
    display: grid;
    text-align: center;
    place-content: center;
}
.tdp_failed_wrp .tdp_fw_check_icon{
    background-color: #2D9CDB;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.tdp_failed_wrp .tdp_fw_message{
    margin: 20px 0px;
    font-size: 16px;
}
.tdp_failed_wrp .tdp_fw_mw_button{
    display: flex;
    width: auto;
    height: 40px;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2D9CDB;
    color: #FFF;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
/* processing screen */
.tdp_processing_wrp{
    width: 100%;
    height: 100%;
    display: grid;
    text-align: center;
    place-content: center;
}
.tdp_processing_wrp .tdp_pw_image{
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.tdp_processing_wrp .tdp_text_bold{
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 600;
}
.tdp_processing_wrp .tdp_text{
    margin: 20px 0px;
    font-size: 16px;
    padding: 0px 20px;
    text-align: center;
}
/* campagin registration screen */
.tdp_campaign_reg_wrp{
    width: calc(100%);
    height: calc(100% - 40px);
    padding: 10px;
}
.tdp_campaign_reg_wrp .tdp_crw_ready_state{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.tdp_campaign_reg_wrp .tdp_crw_no_service_wrp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.tdp_campaign_reg_wrp .tdp_crw_no_service_wrp .tdp_crw_button{
    display: flex;
    width: auto;
    height: 40px;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2D9CDB;
    color: #FFF;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.tdp_campaign_reg_wrp .tdp_crw_failed_wrp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.tdp_campaign_reg_wrp .tdp_crw_failed_wrp .tdp_crw_button{
    display: flex;
    width: auto;
    height: 40px;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2D9CDB;
    color: #FFF;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.tdp_campaign_reg_wrp .tdp_crw_main_wrp{
    padding-right: 10px;
    padding-left: 10px;
    margin: 20px 0px;
}
.tdp_campaign_reg_wrp .tdp_crwmw_button_wrp{
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 50px;
}
.tdp_campaign_reg_wrp .tdp_crwmw_button_wrp .tdp_crwmw_button{
    background: #133159;
    color: #fff;
    display: inline-flex;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}
.tdp_campaign_reg_wrp .tdp_crwmw_body{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.tdp_campaign_reg_wrp .tdp_crwmw_body .tdp_crwmwb_reg_cam_use_case_title{
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    display: block;
}
.tdp_campaign_reg_wrp .tdp_crwmw_body .tdp_crwmwb_reg_cam_use_case_text{
    padding: 0px;
    color: rgb(18, 28, 45);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
}
.tdp_campaign_reg_wrp .tdp_crwmw_body .tdp_crwmwb_field_content{
    margin-bottom: 10px;
    width: 100%;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_lebel{
    color: rgb(18, 28, 45);
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_lebel small{
    margin-left: 10px;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_dropdown_wrp{
    margin-top: 8px;
    width: 100%;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_input textarea{
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    background: #fff;
    color: #8896A8;
    height: 66px;
    resize: none;
    width: 100%;
    font-family: var(--poppins);
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_input textarea:focus {
    outline: 0;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_input_count{ 
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #133159;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_extra_label{
    color: #121c2d;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_checkbox_wrp{
    margin-top: 8px;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_checkbox_wrp .tdp_crwmwbfc_checkbox{
    display: flex;
    grid-gap: 5px;
    color: #121c2d;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_checkbox_wrp .tdp_crwmwbfc_checkbox span:first-child{
    cursor: pointer;
}
.tdp_crwmwb_field_content .tdp_crwmwbfc_dropdown_wrp .MuiSelect-root{
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    padding: 11px 16px!important;
    color: #8896a8;
}
/* ein confirmation */
.tdp_ein_confirmation_wrp{
    width: 100%;
    height: 100%;
    display: grid;
    text-align: center;
    place-content: center;
    padding: 20px;
}
.tdp_ein_confirmation_wrp_alt{
    height: calc(70vh - 80px);
    display: flex;
    width: 100%;
}
.tdp_ein_confirmation_wrp .tdp_ecw_header_wrp{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.tdp_ein_confirmation_wrp .tdp_ecw_header_wrp .tdp_ecwhw_title{
    color: #000;
    font-size: 23px;
    font-weight: 700;
}
.tdp_ein_confirmation_wrp .tdp_ecw_header_wrp .tdp_ecwhw_agency{
    color: #2D9CDB;
    font-size: 23px;
    font-weight: 700;
}
.tdp_ein_confirmation_wrp .tdp_ecw_message_wrp{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 30px 20px 40px 20px;
    border-bottom: 1px solid #E5E8EF;
}
.tdp_ein_confirmation_wrp .tdp_ecw_message_wrp .tdp_ecwmw_text{
    font-size: 12px;
    text-align: justify;
}
.tdp_ein_confirmation_wrp .tdp_ecw_action_wrp {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
}
.tdp_ein_confirmation_wrp .tdp_ecw_action_wrp .tdp_ecwaw_label{
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}
.tdp_ein_confirmation_wrp .tdp_ecw_action_wrp  .tdp_ecwaw_button_wrp{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.tdp_ein_confirmation_wrp .tdp_ecwaw_button_wrp .tdp_ecwawbw_button{
    border: 1px solid #E5E8EF;
    border-radius: 7px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}
/* volumn select */
.tdp_ein_volumn_select_wrp{
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.tdp_ein_volumn_select_wrp .tdp_ein_vsw_header{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E5E8EF;
    padding: 0px 0px 10px 0px;
}
.tdp_ein_volumn_select_wrp .tdp_ein_vsw_header .tdp_ein_vswh_title{
    font-size: 18px;
    font-weight: 600;
}
.tdp_ein_volumn_select_wrp .tdp_ein_vsw_body{
    width: 100%;
    height: calc(100% - 61px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.tdp_ein_volumn_select_wrp .tdp_ein_vsw_body .tdp_ein_vswb_title{
    text-align: center;
    margin-bottom: 60px;
    font-weight: 600;
    font-size: 14px;
}
.tdp_ein_volumn_select_wrp .tdp_ein_vsw_body .tdp_ein_vswb_button_wrp{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.tdp_ein_volumn_select_wrp .tdp_ein_vswb_button_wrp .tdp_ein_vswbbw_button{
    padding: 20px;
    border-radius: 7px;
    border: 1px solid #E5E8EF;
    cursor: pointer;
    text-align: center;
    width: 150px;
    font-weight: 600;
    font-size: 14px;
}
/* extra */
.tdp_ein_confirmation_wrp_alt .tdp_aw_des_wrp{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 10px;
    text-align: justify;
    font-weight: 300;
    font-size: 14px;
}
.tdp_ein_confirmation_wrp_alt .tdp_aw_des_wrp .tdp_aw_dw_title{
    font-weight: bold !important;
}
.tdp_ein_confirmation_wrp_alt .tdp_aw_des_wrp .tdp_aw_dw_link{
    color: #039be5;
    border-bottom: unset;
    text-decoration: none;
    cursor: pointer;
    font-weight: bolder !important;
}

.alertBannerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.alertBanner {
    color: #f44336;
    margin-bottom: 10px;
    border-radius: 4px;
    width: fit-content;
}

.alertBanner p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}