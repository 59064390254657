.customButtonDiv {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(0,0,0,0.2);
    color: white;
    font-size: 16px;
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
}

.customButtonDiv:hover {
    background-color: #45a049;
}

.customButtonDiv:active {
    background-color: #3e8e41;
}

.customButtonDiv.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.dashboard___attention__message__text *{
	margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: inline;
}

.DashboardModule-container-parent-class .dashboard___attention .dashboard___attention__message__text .dashboard-attention-message-event a{
    color: darkblue;
	text-decoration: underline;
}